/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Drawer, Toolbar } from "@mui/material";
import HytchLogo from "assets/images/Logo-White.png";
import { useAppBarHeight } from "hooks";
import { useContext } from "react";
import LayoutContext from "../context";
import ApprovalNavigationList from "./sections/Approvals";
import SearchNavigationList from "./sections/Search";

const Navigation: React.FC = () => {
  const appbarHeight = useAppBarHeight();
  const { isMobileDevice } = useContext(LayoutContext);

  return (
    <Drawer
      variant={isMobileDevice ? "temporary" : "permanent"}
      anchor="left"
      sx={{ flexShrink: 1 }}
      elevation={5}
    >
      <Toolbar
        sx={{
          height: appbarHeight,
          maxHeight: appbarHeight
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center"
          }}
        >
          <img
            src={HytchLogo}
            height={appbarHeight - 15}
            style={{ paddingTop: 3 }}
          />
        </Box>
      </Toolbar>
      <ApprovalNavigationList />
      <SearchNavigationList />
    </Drawer>
  );
};

export default Navigation;
