/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chip, CircularProgress, Grid, Typography } from "@mui/material";
import Section from "components/molecules/Section";
import ProfileContext from "context/profile";
import { capitalize, isEmpty } from "lodash";
import { useContext } from "react";
import { type ProfileAdmin } from "types/profile";

const FONT_COLOR = "#424949";

interface Props {
  profile: ProfileAdmin;
}

interface DataRowProps {
  label: string;
  value: string | number;
}
const DataRow: React.FC<DataRowProps> = ({ label, value }) => {
  return (
    <Grid
      item
      xs={12}
      md={5}
      lg={5}
      container
      direction="row"
      padding={1}
      bgcolor="#F2F3F4"
      columnGap={1}
      justifyContent="center"
    >
      <Typography variant="body2" fontWeight={500} textTransform="capitalize">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight={400}>
        {value}
      </Typography>
    </Grid>
  );
};

const ProfileContent: React.FC<Props> = ({ profile }) => {
  const { loading } = useContext(ProfileContext);
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid
      container
      direction="row"
      bgcolor="white"
      border={1}
      borderRadius={2}
      borderColor="white"
      paddingX={2}
      paddingY={4}
      columnGap={2}
      rowGap={3}
      sx={{ color: FONT_COLOR }}
    >
      <Typography variant="h6" fontSize={16}>
        Personal Information
      </Typography>
      <Grid item xs={12}>
        <Section name="Basic">
          <DataRow label="age" value={`${profile.age} years`} />
          <DataRow label="height" value={`${profile.height} cm`} />
          <DataRow label="gender" value={capitalize(profile.gender)} />
          <DataRow label="ethnicity" value={profile.ethnicity?.name ?? "N/A"} />
          <DataRow label="religion" value={profile.religion?.name ?? "N/A"} />
        </Section>
      </Grid>
      <Grid item xs={12}>
        <Section name="Residency">
          <DataRow
            label="country"
            value={profile.residentCountry?.name ?? "N/A"}
          />
          <DataRow label="city" value={profile.residentCity ?? "N/A"} />
        </Section>
      </Grid>
      <Grid item xs={12}>
        <Section name="Education & Profession">
          <DataRow
            label="education"
            value={profile.educationLevel?.name ?? "N/A"}
          />
          {profile.profession !== undefined && (
            <DataRow label="profession" value={profile.profession.name} />
          )}
          {!isEmpty(profile.professionOther) && (
            <DataRow
              label="profession - other"
              value={profile.professionOther ?? "N/A"}
            />
          )}
        </Section>
      </Grid>
      <Grid item xs={12}>
        <Section name="Habits">
          <DataRow
            label="food preference"
            value={capitalize(profile.foodPreference ?? "N/A")}
          />
          <DataRow
            label="drinking"
            value={capitalize(profile.drinking ?? "N/A")}
          />
          <DataRow
            label="smoking"
            value={capitalize(profile.smoking ?? "N/A")}
          />
        </Section>
      </Grid>
      {profile.hobbies !== undefined && profile.hobbies.length !== 0 && (
        <Grid item xs={12}>
          <Section name="Hobbis">
            {profile.hobbies.map((hobby) => (
              <Chip key={hobby.id} label={hobby.name} size="medium" />
            ))}
          </Section>
        </Grid>
      )}
      <Grid item xs={12}>
        <Section name="Extras">
          <DataRow
            label="match question"
            value={capitalize(profile.matchQuestion)}
          />
        </Section>
      </Grid>
    </Grid>
  );
};

export default ProfileContent;
