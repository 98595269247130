/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticatedTheme from "../../theme";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  useMediaQuery,
  useTheme,
  Alert,
  styled
} from "@mui/material";
import { LayoutProvider } from "./context";
import MainRegion from "./Main";
import Navigation from "./Navigation";
import Appbar from "./Appbar2";
import { getNavIconColor } from "utils/layoutUtil";
import { runPreConfigurations, setAuthenticated } from "features/application";
import ViewLoader from "components/ViewLoader";

const drawerWidth = 220;

const AuthenticatedLayout: React.FC = () => {
  const navigate = useNavigate();
  const { authenticated, preConfigured, appAlert } = useAppSelector(
    (state) => state.application
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!preConfigured) {
      void dispatch(runPreConfigurations());
    } else if (!authenticated) {
      navigate("auth");
    }
  }, [
    preConfigured,
    authenticated
  ]);

  if (!preConfigured) {
    return (
      <Box sx={{ display: "flex", height: "100vh" }}>
        <ViewLoader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={AuthenticatedTheme}>
      <LayoutProvider value={{ drawerWidth, isMobileDevice, getNavIconColor }}>
        <Box sx={{ display: "flex", height: "100vh" }}>
          <CssBaseline />
          <Appbar />
          <Navigation />
          {appAlert !== undefined && (
            <Alert severity={appAlert.severity}>{appAlert.message}</Alert>
          )}
          <MainRegion />
        </Box>
      </LayoutProvider>
    </ThemeProvider>
  );
};

export default AuthenticatedLayout;
