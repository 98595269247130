import { type LoginForm } from "types/form";
import BaseValidator, { ValidationResult } from "../baseValidator";
import FieldValidator from "../../fieldValidator";

export enum Form {
  Login = "LoginForm"
}

class AuthValidator extends BaseValidator {
  validate<T>(form: Form, values: T): ValidationResult {
    const output = new ValidationResult();
    switch (form) {
      case Form.Login:
        return this.validateLogin(output, values as LoginForm);
    }
  }

  private validateLogin(
    output: ValidationResult,
    values: LoginForm
  ): ValidationResult {
    const { phoneNumber, password } = values;
    FieldValidator.isEmpty(output, "phoneNumber", phoneNumber, "Phone Number");
    FieldValidator.isEmpty(output, "password", password);
    return output;
  }
}

const singleton = new AuthValidator();
export default singleton;
