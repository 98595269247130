/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppBreadCrumb from "./Breadcrumb";
import AppConfig from "config";
import { useContext } from "react";
import LayoutContext from "../context";
import { useAppBarHeight } from "hooks";

const Styles: Record<string, React.CSSProperties> = {
  OutletWrapper: {
    backgroundColor: "#D7DBDD",
    borderRadius: 2,
    height: "100%",
    overflowY: "auto"
  }
};

const MainRegion: React.FC = () => {
  const drawerWidth = AppConfig.NAVIGATION_DRAWER_WIDTH;
  const appbarHeight = useAppBarHeight();
  const { isMobileDevice } = useContext(LayoutContext);
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: "#EDEDED" }}>
      <Grid
        container
        direction="column"
        bgcolor="#EDEDED"
        paddingX={4}
        paddingY={2}
        sx={{
          width: isMobileDevice ? 1 : `calc(100% - ${drawerWidth}px)`,
          height: `calc(100% - ${appbarHeight}px)`,
          marginLeft: isMobileDevice ? 0 : `${drawerWidth}px`,
          marginTop: `${appbarHeight}px`
        }}
        height={1}
      >
        <Grid item xs container alignItems="center">
          <AppBreadCrumb />
        </Grid>
        <Grid
          item
          xs={11}
          container
          bgcolor="#D7DBDD"
          height={1}
          alignSelf="stretch"
          sx={{ overflowY: "auto" }}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={12} lg={12} width={1} height={1}>
            <Paper sx={Styles.OutletWrapper} elevation={1}>
              <Grid container direction="row" width={1} height={1}>
                <Outlet />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainRegion;
