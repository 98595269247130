import {
  type UserConfigKey,
  userConfigKeys,
  initialUserConfigs
} from "types/application";

class UserConfig {
  private static instance: UserConfig;
  private readonly configs: Record<UserConfigKey, any>;

  private constructor() {
    this.configs = initialUserConfigs;
    userConfigKeys.forEach((key) => {
      const storageValue = localStorage.getItem(key);
      if (storageValue !== null) {
        this.configs[key] = storageValue;
      }
    });
  }

  public static getInstance(): UserConfig {
    if (UserConfig.instance === undefined) {
      this.instance = new UserConfig();
    }
    return UserConfig.instance;
  }

  public static getValue(key: UserConfigKey): any {
    return this.getInstance().configs[key];
  }

  public static setValue(key: UserConfigKey, value: string): void {
    const proxy = this.getInstance();
    proxy.configs[key] = value;
    localStorage.setItem(key, value);
  }
}

export default UserConfig;
