/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Pagination } from "@mui/material";
import PageHeader from "./PageHeader";
import { useEffect, useState } from "react";
import { type ProfilesView } from "types/profile";
import { useAppDispatch, useAppSelector } from "hooks";
import { fetchPendingEditRequests } from "features/profles";
import { isEmpty } from "lodash";
import AppConfig from "config";
import ProfilesListView from "components/organisms/ProfilesListView";
import ViewLoader from "components/ViewLoader";
import ProfilesTableView from "components/organisms/ProfilesTableView";
import { setBreadcrumbs } from "features/application";

const EditRequestPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pedingEditRequests } = useAppSelector((state) => state.profiles);
  const [
    currentPage,
    setCurrentPage
  ] = useState(AppConfig.DEFAULT_PAGE_NO);
  const [
    viewVaraint,
    setViewVariant
  ] = useState<ProfilesView>("tableView");
  const [
    loading,
    setLoading
  ] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([{ label: "Edit Requests", href: "/edit-requests" }])
    );
    if (isEmpty(pedingEditRequests.results)) {
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      dispatch(fetchPendingEditRequests({ pageNum: currentPage })).finally(
        () => {
          setLoading(false);
        }
      );
    }
  }, [loading]);

  const PageView: React.FC = () => {
    if (loading) {
      return <ViewLoader />;
    }

    return viewVaraint === "listView" ? (
      <ProfilesListView profiles={pedingEditRequests.results} />
    ) : (
      <ProfilesTableView
        parent="EditRequests"
        profiles={pedingEditRequests.results}
        pagination={{
          pageNum: pedingEditRequests.pageNum,
          pageSize: AppConfig.DEFUALT_PAGE_SIZE,
          totalPages: pedingEditRequests.totalPages,
          totalResults: pedingEditRequests.totalResults
        }}
      />
    );
  };

  return (
    <Grid container direction="column" height={1} bgcolor="white">
      <Grid item xs={1} md={1} lg={1}>
        <PageHeader viewVaraint={viewVaraint} setViewVariant={setViewVariant} />
      </Grid>
      <Grid item xs md lg padding={2} sx={{ overflowY: "auto" }}>
        <PageView />
      </Grid>
      <Grid item xs={1} md={1} lg={1}>
        {pedingEditRequests.totalPages === 0 && (
          <Pagination
            count={pedingEditRequests.totalPages}
            color="primary"
            size="large"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditRequestPage;
