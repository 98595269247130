/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  useTheme
} from "@mui/material";
import { useAppDispatch } from "hooks";
import {
  type ProfileSearchForm,
  type ProfileSearchCriteria,
  type ProfilesView
} from "types/profile";
import { type DropdownOption } from "types/application";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { type FormikErrors } from "formik";

const profileSearchOptions: Array<DropdownOption<ProfileSearchCriteria>> = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone Number" },
  { value: "id", label: "Profile Id" }
];

interface State {
  searchForm: ProfileSearchForm;
  setSearchFormValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ProfileSearchForm>>;
  onSubmitSearch: () => Promise<any>;
}

const PageHeader: React.FC<State> = ({
  searchForm,
  setSearchFormValue,
  onSubmitSearch
}) => {
  const dispatch = useAppDispatch();
  const appTheme = useTheme();

  useEffect(() => {
    void setSearchFormValue("query", "");
  }, [searchForm.filter]);

  return (
    <Paper variant="outlined" sx={{ height: "100%" }}>
      <Grid
        container
        height={1}
        justifyContent="flex-end"
        alignItems="center"
        paddingX={3}
      >
        <Grid
          xs={12}
          md={6}
          lg={4}
          item
          container
          direction="row"
          columnGap={1}
        >
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="profile-search-criteria-select">
                Criteria
              </InputLabel>
              <Select
                labelId="profile-search-criteria-select"
                label="Criteria"
                value={searchForm.filter}
                onChange={(e) => {
                  void setSearchFormValue("filter", e.target.value);
                }}
              >
                {profileSearchOptions.map((option) => (
                  <MenuItem
                    key={`profile-search-criteria-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="keyword"
              value={searchForm.query}
              onChange={(e) => {
                void setSearchFormValue("query", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} container alignItems="center">
            <IconButton
              size="medium"
              disableFocusRipple
              sx={{
                backgroundColor: appTheme.palette.primary.main,
                "&:disabled": {
                  backgroundColor: "#BB8FCE"
                }
              }}
              disabled={searchForm.query.length === 0}
              onClick={(e) => {
                void onSubmitSearch();
              }}
            >
              <SearchRoundedIcon fontSize="inherit" sx={{ color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageHeader;
