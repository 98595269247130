import { type ReactElement, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  useTheme
} from "@mui/material";

interface IProps {
  groupName: string;
  children: ReactElement;
}

const NavigationGroup: React.FC<IProps> = ({ groupName, children }) => {
  const theme = useTheme();
  const [
    menuOpen,
    setMenuOpen
  ] = useState(false);

  return (
    <List
      sx={{ width: "100%", bgcolor: theme.palette.primary.main }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        onClick={(e) => {
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
      >
        <ListItemText
          primary={groupName}
          sx={{
            color: theme.palette.common.white,
            textTransform: "capitalize"
          }}
        />
        {menuOpen ? (
          <ExpandLess htmlColor={theme.palette.common.white} />
        ) : (
          <ExpandMore htmlColor={theme.palette.common.white} />
        )}
      </ListItemButton>
      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </List>
  );
};

export default NavigationGroup;
