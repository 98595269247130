interface IAppConfig {
  API_BASE_URL: string | null;
  APP_BAR_DOM_ID: string;
  NAVIGATION_DRAWER_WIDTH: number;
  DEFUALT_PAGE_SIZE: number;
  DEFAULT_PAGE_NO: number;
  DEFAULT_DATETIME_FORMAT: string;
  DEFAULT_APP_ALERT_TIMEOUT: number;
}

const AppConfig: IAppConfig = {
  APP_BAR_DOM_ID: "hytch-app-bar",
  NAVIGATION_DRAWER_WIDTH: 280,
  API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL !== undefined
      ? process.env.REACT_APP_API_BASE_URL
      : null,
  DEFUALT_PAGE_SIZE: 10,
  DEFAULT_PAGE_NO: 0,
  DEFAULT_DATETIME_FORMAT: "YYYY MMMM DD hh:mm:ss A",
  DEFAULT_APP_ALERT_TIMEOUT: 5000 // miliseconds
};

export default AppConfig;
