import AppConfig from "config";
import moment from "moment";

class TimeUtililty {
  private static instance: TimeUtililty;

  private constructor() {}

  static getInstance(): TimeUtililty {
    if (TimeUtililty.instance === undefined) {
      TimeUtililty.instance = new TimeUtililty();
    }
    return this.instance;
  }

  public formatUnixTimeToLocalDateTime(
    utcTime: string,
    pattern?: string
  ): string {
    const utcDateTime = moment.utc(utcTime);
    const localDateTime = utcDateTime.local();
    const formattedDateTime = localDateTime.format(
      pattern ?? AppConfig.DEFAULT_DATETIME_FORMAT
    );
    return formattedDateTime;
  }
}

const instance = TimeUtililty.getInstance();
export default instance;
