import appTheme from "theme";
import { type Location } from "react-router-dom";

export const getNavIconColor = (
  location: Location,
  pathname: string
): string => {
  if (pathname === "/") {
    return pathname === location.pathname
      ? appTheme.palette.primary.main
      : appTheme.palette.common.white;
  }
  return location.pathname.includes(pathname)
    ? appTheme.palette.primary.main
    : appTheme.palette.common.white;
};
