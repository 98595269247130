/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Pagination } from "@mui/material";
import { useState, useEffect } from "react";
import ViewLoader from "components/ViewLoader";
import { useAppDispatch, useAppSelector } from "hooks";
import { isEmpty } from "lodash";
import { fetchInReviewProfiles } from "features/profles";
import ProfilesListView from "components/organisms/ProfilesListView";
import ProfilesTableView from "components/organisms/ProfilesTableView";
import PageHeader from "./PageHeader";
import { type ProfilesView } from "types/profile";
import AppConfig from "config";
import UserConfig from "config/UserConfig";
import { setBreadcrumbs } from "features/application";

const InReviewRequestPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { inReviewProfiles } = useAppSelector((state) => state.profiles);

  const [
    loading,
    setLoading
  ] = useState(false);
  const [
    variant,
    setVaraint
  ] =
    useState<ProfilesView>(UserConfig.getValue("in-review-profile-page-view"));

  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Review Requests", href: "/" }]));
    if (isEmpty(inReviewProfiles.results)) {
      setLoading(true);
      void dispatch(fetchInReviewProfiles({ pageNum: 0 })).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const PageView: React.FC = () => {
    if (loading) {
      return <ViewLoader />;
    }

    return variant === "listView" ? (
      <ProfilesListView profiles={inReviewProfiles.results} />
    ) : (
      <ProfilesTableView
        parent="InReviewRequests"
        profiles={inReviewProfiles.results}
        pagination={{
          pageNum: inReviewProfiles.pageNum,
          pageSize: AppConfig.DEFUALT_PAGE_SIZE,
          totalPages: inReviewProfiles.totalPages,
          totalResults: inReviewProfiles.totalResults
        }}
      />
    );
  };

  return (
    <Grid container direction="column" height={1}>
      <Grid item xs={1} lg={1}>
        <PageHeader viewVaraint={variant} setViewVariant={setVaraint} />
      </Grid>
      <Grid
        xs
        item
        padding={2}
        container
        direction="column"
        height={1}
        flexGrow={1}
        sx={{ overflowY: "auto" }}
        bgcolor="white"
      >
        <PageView />
      </Grid>
      {!loading && inReviewProfiles.totalPages !== 1 && (
        <Grid
          item
          xs={1}
          lg={1}
          container
          justifyContent="flex-end"
          alignItems="center"
          height={1}
          padding={2}
        >
          <Pagination
            count={inReviewProfiles.totalPages}
            color="primary"
            size="large"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InReviewRequestPage;
