import RateReviewIcon from "@mui/icons-material/RateReview";
import EditIcon from "@mui/icons-material/Edit";
import NavigationItem from "components/NavigationItem";
import { useLocation } from "react-router-dom";
import NavigationGroup from "components/NavigationGroup";
import { getNavIconColor } from "utils/layoutUtil";

const ApprovalNavigationList: React.FC = () => {
  const location = useLocation();

  return (
    <NavigationGroup groupName="approvals">
      <>
        <NavigationItem
          label="In Review"
          pathname="/"
          Icon={<RateReviewIcon htmlColor={getNavIconColor(location, "/")} />}
        />
        <NavigationItem
          label="Edit Requests"
          pathname="edit-requests"
          Icon={
            <EditIcon htmlColor={getNavIconColor(location, "edit-requests")} />
          }
        />
      </>
    </NavigationGroup>
  );
};

export default ApprovalNavigationList;
