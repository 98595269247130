import { animated, useSpring } from "@react-spring/web";

interface State {
  img: string;
  initialSize: number;
  noOfFrames: number;
  increment: number;
  duration?: number;
}

const AnimatedSpinner: React.FC<State> = ({
  img,
  initialSize,
  noOfFrames,
  increment,
  duration = 1000
}) => {
  const frames: Array<Record<string, any>> = [];
  let startWidth = initialSize;

  for (let i = 0; i < noOfFrames; i++) {
    const curFrame = { width: startWidth };
    frames.push(curFrame);
    startWidth += increment;
  }
  const springs = useSpring({
    from: { width: initialSize },
    to: frames,
    loop: true,
    reverse: true,
    config: {
      duration
    }
  });

  return <animated.img src={img} alt="hytch-log" style={{ ...springs }} />;
};

export default AnimatedSpinner;
