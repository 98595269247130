import { Paper, Grid, Button, CircularProgress } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import ViewListIcon from "@mui/icons-material/ViewList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { fetchInReviewProfiles } from "features/profles";
import { useAppDispatch } from "hooks";
import { type ProfilesView } from "types/profile";
import { useState } from "react";
import UserConfig from "config/UserConfig";

interface State {
  viewVaraint: ProfilesView;
  setViewVariant: (variant: ProfilesView) => void;
}

const PageHeader: React.FC<State> = ({ viewVaraint, setViewVariant }) => {
  const dispatch = useAppDispatch();
  const [
    refreshing,
    setRefreshing
  ] = useState(false);

  return (
    <Paper variant="outlined" sx={{ height: "100%" }}>
      <Grid
        container
        height={1}
        justifyContent="flex-end"
        alignItems="center"
        paddingRight={1}
      >
        <Grid item>
          <Button
            disabled
            startIcon={
              viewVaraint === "tableView" ? (
                <ViewListIcon fontSize="large" color="inherit" />
              ) : (
                <TableViewIcon fontSize="large" color="inherit" />
              )
            }
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={(e) => {
              e.preventDefault();
              const nextVaraint: ProfilesView =
                viewVaraint === "listView" ? "tableView" : "listView";
              setViewVariant(nextVaraint);
              UserConfig.setValue("in-review-profile-page-view", nextVaraint);
            }}
          >
            {viewVaraint === "listView" ? "Table View" : "List View"}
          </Button>
          <Button
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={(e) => {
              e.preventDefault();
              setRefreshing(true);
              dispatch(
                fetchInReviewProfiles({
                  pageNum: 0
                })
              ).finally(() => {
                setRefreshing(false);
              });
            }}
          >
            {refreshing ? (
              <>
                <CircularProgress size={18} style={{ marginRight: 5 }} />
                Refreshing
              </>
            ) : (
              <>
                <RefreshIcon
                  fontSize="medium"
                  color="primary"
                  style={{ marginRight: 5 }}
                />
                Refresh
              </>
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageHeader;
