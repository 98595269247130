import { type LoginForm } from "types/form";
import HttpAdapter, { decamelizeBody } from "../adapter";

const AuthService = {
  login: async (payload: LoginForm) => {
    const httpResponse = await HttpAdapter.doRequest({
      method: "POST",
      path: "login",
      apiVersion: "v1",
      authenticated: false,
      body: decamelizeBody(payload),
      contentType: "application/x-www-form-urlencoded"
    });
    return httpResponse;
  },
  fetchAuthUserAccount: async () => {
    const httpResponse = await HttpAdapter.doRequest({
      method: "GET",
      path: "account",
      apiVersion: "v1",
      authenticated: true
    });
    return httpResponse;
  }
};

export default AuthService;
