import AppConfig from "config";
import HttpAdapter, { decamelizeBody, decamelizeQueryParams } from "../adapter";
import { type ProfileImage, type ProfileStatus } from "types/profile";

const ProfileService = {
  getPaginatedProfiles: async (
    profileStatus: ProfileStatus,
    pageNum: number
  ) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: "admin/profiles",
      authenticated: true,
      queryParams: decamelizeQueryParams({
        pageNum: pageNum.toString(),
        pageSize: AppConfig.DEFUALT_PAGE_SIZE.toString(),
        status: profileStatus.toString()
      })
    });
    return response;
  },
  fetchProfileByAdmin: async (id: string) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: `admin/profiles_search/${id}`,
      authenticated: true
    });
    return response;
  },
  fetchProfileImage: async (image: ProfileImage) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: `admin/profile_images/${image.id}`,
      authenticated: true,
      reponseType: "blob"
    });
    return response;
  },
  fetchPendingEditRequests: async (pageNum: number) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: `admin/pending_edits`,
      authenticated: true,
      queryParams: decamelizeQueryParams({
        pageNum: pageNum.toString(),
        pageSize: AppConfig.DEFUALT_PAGE_SIZE.toString()
      })
    });
    return response;
  },
  fetchEditRequestImage: async (fileName: string) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: `admin/pending_edit_images/${fileName}`,
      authenticated: true,
      reponseType: "blob"
    });
    return response;
  },
  rejectPendingInReview: async (payload: {
    profileId: string;
    rejection: string;
  }) => {
    const response = await HttpAdapter.doRequest({
      method: "POST",
      apiVersion: "v1",
      path: "admin/profiles/reject",
      authenticated: true,
      body: decamelizeBody({
        profileId: payload.profileId,
        rejectedReason: payload.rejection
      }),
      contentType: "application/x-www-form-urlencoded"
    });
    return response;
  },
  publishProfile: async (payload: {
    profileId: string;
    upgrade: boolean;
    months: string;
  }) => {
    const response = await HttpAdapter.doRequest({
      method: "POST",
      apiVersion: "v1",
      path: "admin/profiles/publish",
      authenticated: true,
      body: decamelizeBody(payload),
      contentType: "application/x-www-form-urlencoded"
    });
    return response;
  },
  acceptOrRejectEditRequest: async (requestId: string, approval: boolean) => {
    const response = await HttpAdapter.doRequest({
      method: "POST",
      apiVersion: "v1",
      path: `admin/pending_edits/${requestId}/approve`,
      authenticated: true,
      body: {
        approve: approval
      },
      contentType: "application/x-www-form-urlencoded"
    });
    return response;
  },
  searchProfilesAdmin: async (filter: string, query: string) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: "admin/profiles_search",
      authenticated: true,
      queryParams: {
        [filter]: query
      }
    });
    return response;
  },
  deleteProfileImage: async (id: string) => {
    const response = await HttpAdapter.doRequest({
      method: "DELETE",
      apiVersion: "v1",
      path: `admin/profile_images/${id}`,
      authenticated: true
    });
    return response;
  },
  getProfileSubscriptionInfo: async (profileId: string) => {
    const response = await HttpAdapter.doRequest({
      method: "GET",
      apiVersion: "v1",
      path: `admin/profiles/${profileId}/purchase_info`,
      authenticated: true
    });
    return response;
  }
};

export default ProfileService;
