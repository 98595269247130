import { Modal, Box, Typography, Grid, useTheme } from "@mui/material";
import { type ReactNode } from "react";

interface Props {
  heading: string;
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
}

const AppModal: React.FC<Props> = ({ heading, open, onClose, children }) => {
  const appTheme = useTheme();
  return (
    <Modal
      open={open}
      onClose={() => {
        if (onClose !== undefined) {
          onClose();
        }
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "30%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          bgcolor: "white",
          border: `1px solid ${appTheme.palette.primary.main}`,
          boxShadow: 24,
          p: 2,
          color: "#2C3E50"
        }}
      >
        <Typography id="modal-modal-title" variant="h6" fontSize={16}>
          {heading}
        </Typography>
        <Grid container direction="row" padding={2}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AppModal;
