/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from "@mui/material";
import PageHeader from "./PageHeader";
import { useAppDispatch, useAppSelector } from "hooks";
import { searchProfiles } from "features/profles";
import { useFormik } from "formik";
import SearchedProfilesTable from "views/profile/SearchedProfilesTable";
import ViewLoader from "components/ViewLoader";
import { useEffect } from "react";
import NoContentView from "views/generic/NoContent";
import { setBreadcrumbs } from "features/application";

const ProfileSearchPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { searchedProfilesResult } = useAppSelector((state) => state.profiles);
  const searchForm = useFormik({
    initialValues: searchedProfilesResult.searchForm,
    onSubmit: async (values) => {
      void dispatch(searchProfiles(values));
    }
  });

  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Profiles", href: "/profiles" }]));
    void searchForm.setFieldValue(
      "filter",
      searchedProfilesResult.searchForm.filter
    );
    void searchForm.setFieldValue(
      "query",
      searchedProfilesResult.searchForm.query
    );
  }, []);

  const PageView: React.FC = () => {
    if (searchForm.isSubmitting) {
      return <ViewLoader />;
    }
    if (searchedProfilesResult.results.length === 0) {
      return <NoContentView heading="No Searched Results" />;
    }
    return (
      <SearchedProfilesTable
        searchedProfiles={searchedProfilesResult.results}
        parentComponent="Profiles"
      />
    );
  };

  return (
    <Grid container direction="column" height={1} bgcolor="white">
      <Grid item xs={1} md={1} lg={1}>
        <PageHeader
          searchForm={searchForm.values}
          setSearchFormValue={searchForm.setFieldValue}
          onSubmitSearch={searchForm.submitForm}
        />
      </Grid>
      <Grid item xs md lg padding={2} sx={{ overflowY: "auto" }}>
        <PageView />
      </Grid>
    </Grid>
  );
};

export default ProfileSearchPage;
