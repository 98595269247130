import { type GenericData } from "types/generics";

export type ProfilesView = "tableView" | "listView";
export interface PaginatedProfiles {
  pageNum: number;
  pageSize: number;
  results: PublicProfile[];
  totalPages: number;
  totalResults: number;
}

export enum ProfileStatus {
  CREATED = "CREATED",
  IN_REVIEW = "IN_REVIEW",
  LIVE = "LIVE",
  EXPIRED = "EXPIRED",
  DISABLED = "DISABLED",
  PAUSED = "PAUSED",
  REVIEW_REJECTED = "REVIEW_REJECTED"
}

export interface ProfileImage {
  id: string;
  createdAt: string;
  isPreferred: boolean;
}

interface ProfileHobby {
  id: number;
  name: string;
}

export interface PublicProfile {
  age: number;
  drinking: string;
  educationLevel: GenericData.EducationLevel;
  ethnicity: GenericData.Ethnicity;
  externalProfile: boolean;
  firstName: string;
  foodPreference: string;
  gender: GenericData.Gender;
  height: number;
  id: string;
  lastName: string;
  matchQuestion: string;
  profession?: GenericData.Profession;
  professionOther?: string;
  publicAvatar: string;
  religion: GenericData.Religion;
  residentCity: string;
  residentCountry: GenericData.Country;
  smoking: string;
  images?: string[];
}

export interface RejectedReview {
  createdAt: string;
  reason: string;
}

export interface ProfileAdmin {
  id: string;
  description?: string;
  firstName: string;
  lastName: string;
  gender: GenericData.Gender;
  dob: string;
  age: number;
  height: number;
  createdAt: string;
  ethnicity: GenericData.Ethnicity;
  religion: GenericData.Religion;
  residentCountry: GenericData.Country;
  residentCity: string;
  educationLevel: GenericData.EducationLevel;
  profession?: GenericData.Profession;
  professionOther?: string;
  drinking: string;
  smoking: string;
  foodPreference: string;
  publicAvatar: string;
  images: string[];
  matchQuestion: string;
  status: ProfileStatus;
  editRequests: EditRequest[];
  externalProfile: boolean;
  poruwaEligible: boolean;
  trialMode: boolean;
  profileImages: ProfileImage[];
  rejectedReviews: RejectedReview[];
  hobbies?: ProfileHobby[];
}

export enum EditRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export interface EditRequest {
  id: string;
  createdAt: string;
  status: EditRequestStatus;
  profileQuestion?: string;
  images?: string[];
  description?: string;
}

const profileSearchCriterias = [
  "name",
  "id",
  "phone"
] as const;
export type ProfileSearchCriteria = (typeof profileSearchCriterias)[number];

export interface ProfileSearchForm {
  filter: ProfileSearchCriteria;
  query: string;
}

export interface SearchProfilesResult {
  searchForm: ProfileSearchForm;
  results: SearchedProfile[];
}

export interface SearchedProfile {
  profile: ProfileAdmin;
  createdAt: string;
  lastSeen: string;
  nonLkUser: boolean;
  phoneNumber: string;
}

export interface UserMeta {
  createdAt: string;
  lastSeen: string;
  nonLKUser: boolean;
  phoneNumber: string;
}

export interface SubscriptionInfo {
  type: string;
  orderId: string;
  months: number;
  currentStart: string;
  currentExpiry: string;
  autoRenew: boolean;
  currency?: string;
  amount?: number;
}
