import { Grid, Typography, useTheme } from "@mui/material";
import NoContentImg from "assets/images/no-search-results.png";

interface Props {
  heading: string;
}

const NoContentView: React.FC<Props> = ({ heading }) => {
  const appTheme = useTheme();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height={1}
    >
      <Grid
        item
        lg={4}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={NoContentImg} alt="no-content" width={240} />
        <Typography
          textAlign="center"
          variant="h5"
          color={appTheme.palette.primary.main}
          textTransform="capitalize"
          width={1}
        >
          {heading}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoContentView;
