/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  useTheme
} from "@mui/material";
import { type UserMeta, type ProfileAdmin } from "types/profile";
import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";

interface Props {
  profile: ProfileAdmin;
  userMeta?: UserMeta;
  fetchProfile: () => Promise<void>;
  deleteProfileImage?: (image: string) => Promise<void>;
}

const ProfileView: React.FC<Props> = ({
  profile,
  userMeta,
  fetchProfile,
  deleteProfileImage
}) => {
  return (
    <Grid container direction="row" rowGap={2}>
      <Grid xs={12}>
        <ProfileHeader
          profile={profile}
          userMeta={userMeta}
          fetchProfile={fetchProfile}
          deleteProfileImage={deleteProfileImage}
        />
      </Grid>
      <Grid xs={12}>
        <ProfileContent profile={profile} />
      </Grid>
    </Grid>
  );
};

export default ProfileView;
