import React from "react";
import { type ProfileAdmin, type UserMeta } from "types/profile";

interface State {
  profile?: ProfileAdmin;
  setProfile: (profile: ProfileAdmin) => void;
  userMeta?: UserMeta;
  setUserMeta: (userMeta: UserMeta) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  fetchProfile?: () => void;
}
const ProfileContext = React.createContext<State>({
  profile: undefined,
  setProfile: (profile: ProfileAdmin) => {},
  userMeta: undefined,
  setUserMeta: (userMeta: UserMeta) => {},
  loading: false,
  setLoading: (value) => {},
  fetchProfile: undefined
});

export default ProfileContext;
