import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { setBreadcrumbs } from "features/application";
import { useAppDispatch } from "hooks";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { type PublicProfile } from "types/profile";

interface Props {
  profiles: PublicProfile[];
  pagination: {
    pageNum: number;
    pageSize: number;
    totalPages: number;
    totalResults: number;
  };
  parent: "EditRequests" | "InReviewRequests";
}

const ProfilesTableView: React.FC<Props> = ({
  profiles,
  pagination,
  parent
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickTableRow = (profile: PublicProfile): void => {
    navigate(`/profiles/${profile.id}`, {
      state: { profileId: profile.id }
    });
    dispatch(
      setBreadcrumbs([
        {
          label:
            parent === "EditRequests" ? "Edit Requests" : "Review Requests",
          href: parent === "EditRequests" ? "/edit-requests" : "/"
        },
        {
          label: `${profile.firstName} ${profile.lastName}`,
          href: `/profiles/${profile.id}`
        }
      ])
    );
  };
  const extractFieldLabel = (
    profile: PublicProfile,
    field: "profession"
  ): string => {
    let label = "N/A";
    const { profession, professionOther } = profile;
    switch (field) {
      case "profession":
        label =
          professionOther !== undefined && !isEmpty(professionOther)
            ? professionOther
            : profession?.name ?? "N/A";
        break;
      default:
        break;
    }
    return label;
  };

  return (
    <Grid container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E5E7E9" }}>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Gender</TableCell>
              <TableCell align="center">Profession</TableCell>
              <TableCell align="center">Resident Country</TableCell>
              <TableCell align="center">Ethnicity</TableCell>
              <TableCell align="center">Religion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => (
              <TableRow
                key={profile.id}
                onClick={(e) => {
                  e.preventDefault();
                  onClickTableRow(profile);
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#E8DAEF"
                  }
                }}
              >
                <TableCell align="center">{profile.id}</TableCell>
                <TableCell align="center">{profile.firstName}</TableCell>
                <TableCell align="center">{profile.lastName}</TableCell>
                <TableCell align="center">{profile.gender}</TableCell>
                <TableCell align="center">
                  {extractFieldLabel(profile, "profession")}
                </TableCell>
                <TableCell align="center">
                  {profile.residentCountry.name}
                </TableCell>
                <TableCell align="center">{profile.ethnicity.name}</TableCell>
                <TableCell align="center"> {profile.religion.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination.pageNum !== 0 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          count={pagination.totalResults}
          rowsPerPage={pagination.pageSize}
          page={pagination.pageNum}
          onPageChange={() => {
            console.log(">>>> onPageChange >>>> ");
          }}
        />
      )}
    </Grid>
  );
};

export default ProfilesTableView;
