import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { type RejectedReview } from "types/profile";
import TimeUtility from "utils/time";

interface Props {
  rejectedReviews: RejectedReview[];
}

const ProfileRejectedReviews: React.FC<Props> = ({ rejectedReviews }) => {
  console.log(">>>> rejectedReviews ", rejectedReviews);
  return (
    <Grid
      container
      direction="column"
      bgcolor="white"
      border={1}
      borderRadius={2}
      borderColor="white"
      paddingX={2}
      paddingY={4}
      columnGap={2}
      rowGap={3}
      sx={{ color: "#424949" }}
    >
      <Grid item>
        <Typography variant="h6" fontSize={16}>
          Rejected Reviews
        </Typography>
      </Grid>
      <Grid item>
        {rejectedReviews === undefined || rejectedReviews.length === 0 ? (
          <Typography variant="caption">No Rejected Reviews</Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E5E7E9" }}>
                  <TableCell align="center">Created At</TableCell>
                  <TableCell align="center">Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rejectedReviews.map((review) => (
                  <TableRow key={review.createdAt}>
                    <TableCell align="center">
                      {TimeUtility.formatUnixTimeToLocalDateTime(
                        review.createdAt
                      )}
                    </TableCell>
                    <TableCell align="center">{review.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileRejectedReviews;
