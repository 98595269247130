/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Fade, Grid, Paper, ThemeProvider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import appTheme from "theme";
import HytchLogo from "assets/images/Logo-White.png";
import AnimatedSpinner from "components/AnimatedSpinner";
import { runPreConfigurations } from "features/application";

const PublicLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authenticated, preConfigured } = useAppSelector(
    (state) => state.application
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!preConfigured) {
      void dispatch(runPreConfigurations());
    }
  }, [preConfigured]);

  useEffect(() => {
    if (preConfigured && authenticated) {
      navigate("/");
    }
  }, [
    authenticated,
    preConfigured
  ]);

  return (
    <ThemeProvider theme={appTheme}>
      <Box width="100vw" height="100vh">
        <Grid container direction="row" alignItems="stretch" height={1}>
          <Grid
            item
            xs={12}
            container
            bgcolor={appTheme.palette.primary.main}
            alignItems="center"
            justifyContent="center"
            sx={{
              background:
                "linear-gradient(to right top, #884ea0, #8c4a9a, #8f4594, #92418e, #943c88)"
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {preConfigured ? (
                <Paper elevation={5} sx={{ paddingBottom: 8, borderRadius: 5 }}>
                  <Outlet />
                </Paper>
              ) : (
                <AnimatedSpinner
                  img={HytchLogo}
                  initialSize={40}
                  noOfFrames={2}
                  increment={40}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default PublicLayout;
