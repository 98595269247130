import { Grid, Typography } from "@mui/material";
import { type ProfileAdmin } from "types/profile";
import ProfileEditRequest from "../ProfileEditRequest";

const FONT_COLOR = "#424949";

interface Props {
  profile: ProfileAdmin;
  fetchProfile: () => Promise<void>;
}

const ProfileEditRequestView: React.FC<Props> = ({ profile, fetchProfile }) => {
  return (
    <Grid
      container
      direction="column"
      bgcolor="white"
      border={1}
      borderRadius={2}
      borderColor="white"
      paddingX={2}
      paddingY={4}
      columnGap={2}
      rowGap={2}
      sx={{ color: FONT_COLOR }}
    >
      <Typography variant="h6" fontSize={16}>
        Edit Requests
      </Typography>
      {profile.editRequests.map((editRequest) => (
        <ProfileEditRequest
          key={editRequest.id}
          editRequest={editRequest}
          profile={profile}
          fetchProfile={fetchProfile}
        />
      ))}
    </Grid>
  );
};

export default ProfileEditRequestView;
