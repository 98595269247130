/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, Grid, Typography } from "@mui/material";
import { deleteProfileImage, fetchProfileById } from "features/profles";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HttpError from "types/generics/HttpError";
import {
  type SearchedProfile,
  type ProfileAdmin,
  type UserMeta
} from "types/profile";
import ProfileView from "components/organisms/ProfileView";
import ProfileEditRequestView from "components/organisms/ProfileEditRequestView";
import ProfileRejectedReviews from "components/organisms/ProfileRejectedReviews";
import SubscriptionInfoView from "components/organisms/SubscriptionInfoView";
import { ErrorHandler } from "helpers/error-handler";

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const routeParams = useParams();
  const [
    profile,
    setProfile
  ] = useState<ProfileAdmin | undefined>(undefined);
  const [
    userMeta,
    setUserMeta
  ] = useState<UserMeta | undefined>(undefined);
  const [
    loading,
    setLoading
  ] = useState(false);

  useEffect(() => {
    const { profileId } = routeParams;
    if (profile === undefined && profileId !== undefined) {
      void fetchProfile();
    }
  }, [routeParams]);

  const fetchProfile = async (): Promise<void> => {
    const { profileId } = routeParams;
    if (profileId !== undefined) {
      try {
        setLoading(true);
        const response = await dispatch(fetchProfileById({ profileId }));
        if (response.payload instanceof HttpError) {
          ErrorHandler.processHttpError(
            dispatch,
            response.payload.httpResponse()
          );
        } else {
          const searchedProfile = response.payload as SearchedProfile;
          setProfile(searchedProfile.profile);
          setUserMeta({
            createdAt: searchedProfile.createdAt,
            lastSeen: searchedProfile.lastSeen,
            nonLKUser: searchedProfile.nonLkUser,
            phoneNumber: searchedProfile.phoneNumber
          });
        }
      } catch (error) {
        ErrorHandler.process(dispatch, {
          severity: "error",
          message: "Unexpected Error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteProfileImageById = async (imageId: string): Promise<void> => {
    try {
      const response = await dispatch(deleteProfileImage({ id: imageId }));
      if (response instanceof HttpError) {
        ErrorHandler.processHttpError(dispatch, response.httpResponse());
      }
      void fetchProfile();
    } catch (error) {
      ErrorHandler.process(dispatch, {
        severity: "error",
        message: "Unexpected Error"
      });
    }
  };

  if (profile === undefined || loading) {
    return (
      <Grid container height={1} justifyContent="center" alignItems="center">
        <CircularProgress size={24} />
      </Grid>
    );
  }

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid
        item
        xs={11}
        md={11}
        lg={8}
        container
        direction="column"
        rowGap={2}
        marginY={2}
      >
        <ProfileView
          profile={profile}
          fetchProfile={fetchProfile}
          userMeta={userMeta}
          deleteProfileImage={deleteProfileImageById}
        />
        <SubscriptionInfoView profile={profile} />
        {profile.editRequests !== undefined &&
          profile.editRequests.length !== 0 && (
            <ProfileEditRequestView
              profile={profile}
              fetchProfile={fetchProfile}
            />
          )}
        <ProfileRejectedReviews rejectedReviews={profile.rejectedReviews} />
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
