import { type RootState } from "store";
import { showAppAlert } from "features/application";
import { type IAppAlert } from "types/application";
import { type AnyAction, type ThunkDispatch } from "@reduxjs/toolkit";
import { type HttpResponse } from "services/api/adapter";
import AppConfig from "config";

interface ServerError {
  code: number;
  description: string;
}

const isServerError = (response: HttpResponse): boolean => {
  if (response.body === undefined || response.body === null) {
    return false;
  }
  const responseBody = response.body as Record<string, unknown>;
  return (
    typeof responseBody.code === "number" &&
    typeof responseBody.description === "string"
  );
};

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ErrorHandler {
  private constructor() {}

  public static process(
    dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
    appAlert: IAppAlert
  ): void {
    appAlert.timeout = appAlert.timeout ?? AppConfig.DEFAULT_APP_ALERT_TIMEOUT;
    void dispatch(showAppAlert(appAlert));
  }

  public static processHttpError(
    dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
    response: HttpResponse,
    timeout?: number
  ): void {
    if (response.success) {
      return;
    }
    // Http-Adapter Error other than Axios
    if (response.status === 600) {
      const appAlert: IAppAlert = {
        severity: "error",
        message: "Unexpected Error Occurred",
        timeout: AppConfig.DEFAULT_APP_ALERT_TIMEOUT
      };
      void dispatch(showAppAlert(appAlert));
      return;
    }
    if (isServerError(response)) {
      const serverError = response.body as ServerError;
      const appAlert: IAppAlert = {
        severity: "error",
        message: `${serverError.description} (${serverError.code})`,
        timeout: timeout ?? AppConfig.DEFAULT_APP_ALERT_TIMEOUT
      };
      void dispatch(showAppAlert(appAlert));
    }
  }
}
