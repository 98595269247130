import {
  ProfileStatus,
  type PaginatedProfiles,
  type ProfileImage,
  type SearchProfilesResult,
  type ProfileSearchForm,
  type SearchedProfile
} from "types/profile";
import AppConfig from "config";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProfileService from "services/api/profile";
import HttpError from "types/generics/HttpError";

const { DEFUALT_PAGE_SIZE } = AppConfig;

interface State {
  inReviewProfiles: PaginatedProfiles;
  pedingEditRequests: PaginatedProfiles;
  searchedProfilesResult: SearchProfilesResult;
}

const initialState: State = {
  inReviewProfiles: {
    results: [],
    pageNum: 0,
    pageSize: DEFUALT_PAGE_SIZE,
    totalPages: 0,
    totalResults: 0
  },
  pedingEditRequests: {
    results: [],
    pageNum: 0,
    pageSize: DEFUALT_PAGE_SIZE,
    totalPages: 0,
    totalResults: 0
  },
  searchedProfilesResult: {
    searchForm: {
      filter: "id",
      query: ""
    },
    results: []
  }
};

const ProfileSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    setInReviewProfiles: (state, action) => {
      state.inReviewProfiles = action.payload;
    },
    setPendingEditRequests: (state, action) => {
      state.pedingEditRequests = action.payload;
    },
    setSearchedProfilesResult: (state, action) => {
      state.searchedProfilesResult = action.payload;
    }
  }
});

const {
  setInReviewProfiles,
  setPendingEditRequests,
  setSearchedProfilesResult
} = ProfileSlice.actions;

export default ProfileSlice.reducer;

interface fetchInReviewProfilesArg {
  pageNum: number;
}
export const fetchInReviewProfiles = createAsyncThunk(
  "profiles/fetch-in-review-profiles",
  async (payload: fetchInReviewProfilesArg, { dispatch }) => {
    const apiResult = await ProfileService.getPaginatedProfiles(
      ProfileStatus.IN_REVIEW,
      payload.pageNum
    );
    if (apiResult.success) {
      dispatch(setInReviewProfiles(apiResult.body));
    }
  }
);

export const fetchPendingEditRequests = createAsyncThunk(
  "profiles/fetch-edit-requests",
  async ({ pageNum }: { pageNum: number }, { dispatch }) => {
    const apiResponse = await ProfileService.fetchPendingEditRequests(pageNum);
    if (apiResponse.success) {
      dispatch(setPendingEditRequests(apiResponse.body));
    }
  }
);

export const fetchProfileById = createAsyncThunk(
  "profile/fetch-profile-by-id",
  async ({ profileId }: { profileId: string }, { rejectWithValue }) => {
    const apiResponse = await ProfileService.fetchProfileByAdmin(profileId);
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
    return apiResponse.body;
  }
);

export const fetchProfileImage = createAsyncThunk(
  "profile/fetch-profile-image",
  async ({ image }: { image: ProfileImage }) => {
    const apiResponse = await ProfileService.fetchProfileImage(image);
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
    return URL.createObjectURL(apiResponse.body);
  }
);

export const fetchEditRequestImage = createAsyncThunk(
  "profile/fetch-edit-request-image",
  async (fileName: string) => {
    const apiResponse = await ProfileService.fetchEditRequestImage(fileName);
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
    return URL.createObjectURL(apiResponse.body);
  }
);

export const rejectPendingInReview = createAsyncThunk(
  "profile/reject-pending-in-review",
  async (payload: { profileId: string; rejection: string }) => {
    const apiResponse = await ProfileService.rejectPendingInReview(payload);
    return apiResponse.success ? true : new HttpError(apiResponse);
  }
);

export const publishProfile = createAsyncThunk(
  "profile/publish-profile",
  async (payload: { profileId: string; upgrade: boolean; months: string }) => {
    const apiResponse = await ProfileService.publishProfile(payload);
    return apiResponse.success ? true : new HttpError(apiResponse);
  }
);

export const acceptOrRejectEditRequest = createAsyncThunk(
  "profile/accept-or-reject-edit-request",
  async ({
    requestId,
    action
  }: {
    requestId: string;
    action: "accept" | "reject";
  }) => {
    const apiResponse = await ProfileService.acceptOrRejectEditRequest(
      requestId,
      action === "accept"
    );
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
  }
);

export const searchProfiles = createAsyncThunk(
  "profile/search-profiles",
  async (searchForm: ProfileSearchForm, { dispatch }) => {
    const apiResponse = await ProfileService.searchProfilesAdmin(
      searchForm.filter,
      searchForm.query
    );
    if (!apiResponse.success) {
      // TODO: handle errors
      return;
    }
    const searchedProfilesResult: SearchProfilesResult = {
      searchForm,
      results: apiResponse.body as SearchedProfile[]
    };
    dispatch(setSearchedProfilesResult(searchedProfilesResult));
  }
);

export const deleteProfileImage = createAsyncThunk(
  "profile/delete-profile-image",
  async ({ id }: { id: string }) => {
    const apiResponse = await ProfileService.deleteProfileImage(id);
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
  }
);

export const getSubscriptionInfo = createAsyncThunk(
  "profile/get-subscription-info",
  async ({ profileId }: { profileId: string }) => {
    const apiResponse = await ProfileService.getProfileSubscriptionInfo(
      profileId
    );
    if (!apiResponse.success) {
      return new HttpError(apiResponse);
    }
    return apiResponse.body;
  }
);
