import { createTheme } from "@mui/material";
import AppConfig from "config";

// const PRIMARY_COLOR = "#C061A3";
const PRIMARY_COLOR = "#884EA0";
const ELEMENT_HOVER_COLOR = "#AF7AC5";
const ICON_BUTTON_HOVER_COLOR = "#6C3483";

const AuthenticatedTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    action: {
      hover: ELEMENT_HOVER_COLOR
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: AppConfig.NAVIGATION_DRAWER_WIDTH,
          backgroundColor: PRIMARY_COLOR
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: PRIMARY_COLOR
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: ICON_BUTTON_HOVER_COLOR
          }
        }
      }
    }
  }
});

export default AuthenticatedTheme;
