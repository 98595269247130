/* eslint-disable @typescript-eslint/no-unused-vars */
import AppConfig from "config";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { AppDisptch, RootState } from "store";

export const useAppDispatch: () => AppDisptch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppBarHeight: () => number = () => {
  const [
    height,
    setHeight
  ] = useState(0);

  useEffect(() => {
    const domElement = document.getElementById(AppConfig.APP_BAR_DOM_ID);
    if (domElement !== null) {
      const { clientHeight } = domElement;
      setHeight(clientHeight);
    }
  }, []);

  return height;
};
