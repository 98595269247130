import { Grid, Typography } from "@mui/material";

interface SectionProps {
  children: React.ReactNode;
  name: string;
}
const Section: React.FC<SectionProps> = ({ children, name }) => {
  return (
    <Grid container rowGap={2} paddingX={1}>
      <Grid xs={12}>
        <Typography
          variant="body1"
          color="#566573"
          fontWeight={500}
          textTransform="capitalize"
        >
          {name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        container
        rowGap={1}
        columnGap={2}
        paddingX={2}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Section;
