/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState } from "react";
import MUIAppBar from "@mui/material/AppBar";
import {
  Avatar,
  Badge,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AppConfig from "config";
import LayoutContext from "../context";
import HytchLogo from "assets/images/Logo-White.png";
import { useAppDispatch } from "hooks";
import { logoutAuth } from "features/application";

const AppBarIconButton = styled(IconButton)({
  color: "white"
});

const Appbar: React.FC = () => {
  const drawerWidth = AppConfig.NAVIGATION_DRAWER_WIDTH;
  const dispatch = useAppDispatch();
  const { isMobileDevice } = useContext(LayoutContext);
  const [
    leftMenuAnchor,
    setLeftMenuAnchor
  ] = useState<null | HTMLElement>(null);

  const showLeftMenu = Boolean(leftMenuAnchor);

  const onCloseLeftMenu = (): void => {
    setLeftMenuAnchor(null);
  };

  return (
    <MUIAppBar
      id={AppConfig.APP_BAR_DOM_ID}
      position="fixed"
      elevation={5}
      sx={{
        width: isMobileDevice ? 1 : `calc(100% - ${drawerWidth}px)`,
        ml: isMobileDevice ? 0 : `${drawerWidth}px`
      }}
    >
      <Toolbar>
        <Grid
          container
          justifyContent={isMobileDevice ? "space-between" : "flex-end"}
        >
          {isMobileDevice && (
            <Grid item>
              <AppBarIconButton
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
              >
                <MenuIcon />
                <img
                  src={HytchLogo}
                  height={40}
                  style={{ paddingTop: 3, marginLeft: 5 }}
                />
              </AppBarIconButton>
            </Grid>
          )}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* <Grid item>
              <AppBarIconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="messages"
              >
                <Badge variant="standard" color="error" badgeContent={10}>
                  <MailIcon />
                </Badge>
              </AppBarIconButton>
              <AppBarIconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="notifications"
              >
                <Badge variant="standard" color="error" badgeContent={3}>
                  <NotificationsIcon />
                </Badge>
              </AppBarIconButton>
            </Grid> */}
            <Button
              onClick={(e) => {
                setLeftMenuAnchor(e.currentTarget);
              }}
              startIcon={<AccountCircleRoundedIcon fontSize="inherit" />}
              endIcon={<ArrowDropDownIcon />}
              size="large"
              id="basic-button"
              aria-controls={showLeftMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={showLeftMenu ? "true" : undefined}
              color="inherit"
              variant="text"
              sx={{ textTransform: "capitalize" }}
            >
              Admin User
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={leftMenuAnchor}
              open={showLeftMenu}
              onClose={() => {
                onCloseLeftMenu();
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {/* <MenuItem onClick={onCloseLeftMenu}>Profile</MenuItem> */}
              {/* <MenuItem onClick={onCloseLeftMenu}>My account</MenuItem> */}
              <MenuItem
                onClick={(e) => {
                  onCloseLeftMenu();
                  void dispatch(logoutAuth());
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </MUIAppBar>
  );
};

export default Appbar;
