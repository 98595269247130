/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useAppSelector } from "hooks";
import { type ReactNode } from "react";
import { type Location, useLocation, useNavigate } from "react-router-dom";

const AppBreadCrumb: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breadcrumbs } = useAppSelector((state) => state.application);

  const getBreadcrumb = (location: Location): ReactNode[] | ReactNode => {
    const { pathname } = location;
    let breadcrumbName = "";
    switch (pathname) {
      case "/edit-requests":
        breadcrumbName = "Edit Requests";
        break;
      default:
        breadcrumbName = "Review Requests";
        break;
    }

    return (
      <Link underline="hover" key="1" color="inherit">
        <Typography variant="h6">{breadcrumbName}</Typography>
      </Link>
    );
  };

  return (
    <Breadcrumbs>
      {breadcrumbs.map((breadcrumb) => (
        <Link
          underline="hover"
          key="1"
          color="inherit"
          onClick={(e) => {
            e.preventDefault();
            navigate(breadcrumb.href);
          }}
        >
          <Typography variant="h6">{breadcrumb.label}</Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default AppBreadCrumb;
