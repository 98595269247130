// export interface ValidationResult {
//   validity: boolean;
//   errors: Record<string, any>;
// }

export class ValidationResult {
  private _validity: boolean;
  private readonly _errors: Record<string, string[]>;

  constructor() {
    this._validity = true;
    this._errors = {};
  }

  get validity(): boolean {
    return this._validity;
  }

  set validity(value: boolean) {
    this._validity = value;
  }

  get errors(): Record<string, string[]> {
    return this._errors;
  }
}

abstract class BaseValidator {
  abstract validate<T>(form: string, data: T): ValidationResult;
}

export default BaseValidator;
