import { createBrowserRouter } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import AuthenticatedLayout from "layouts/Authenticated";
import PublicLayout from "layouts/Public";
import LoginScreen from "pages/Login";
import InReviewRequestPage from "pages/InReviewRequests";
import EditRequestPage from "pages/EditRequests";
import ProfileSearchPage from "pages/ProfileSearch";
import ProfilePage from "pages/Profile";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <AuthenticatedLayout />,
    children: [
      {
        index: true,
        element: <InReviewRequestPage />
      },
      {
        path: "edit-requests",
        element: <EditRequestPage />
      },
      {
        path: "profiles",
        element: <ProfileSearchPage />
      },
      {
        path: "profiles/:profileId",
        element: <ProfilePage />
      }
    ]
  },
  {
    path: "auth",
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <LoginScreen />
      }
    ]
  }
];

const router = createBrowserRouter(routes);
export default router;
