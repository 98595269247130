import { Grid } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import HytchLogo from "assets/images/Logo.png";

interface State {
  initialSize?: number;
  noOfFrames?: number;
  increment?: number;
  duration?: number;
}

const ViewLoader: React.FC<State> = ({
  initialSize = 50,
  noOfFrames = 6,
  increment = 10,
  duration = 500
}) => {
  const frames: Array<Record<string, any>> = [];
  let startWidth = initialSize;

  for (let i = 0; i < noOfFrames; i++) {
    const curFrame = { width: startWidth };
    frames.push(curFrame);
    startWidth += increment;
  }
  const springs = useSpring({
    from: { width: initialSize },
    to: [...frames],
    loop: true,
    reverse: true,
    config: {
      duration
    }
  });

  return (
    <Grid container height={1} justifyContent="center" alignItems="center">
      <animated.img src={HytchLogo} alt="hytch-log" style={{ ...springs }} />
    </Grid>
  );
};

export default ViewLoader;
