import PeopleIcon from "@mui/icons-material/People";
import NavigationGroup from "components/NavigationGroup";
import NavigationItem from "components/NavigationItem";
import LayoutContext from "layouts/Authenticated/context";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

const SearchNavigationList: React.FC = () => {
  const location = useLocation();
  const { getNavIconColor } = useContext(LayoutContext);
  return (
    <NavigationGroup groupName="search">
      <NavigationItem
        label="Profiles"
        pathname="profiles"
        Icon={<PeopleIcon htmlColor={getNavIconColor(location, "profiles")} />}
      />
    </NavigationGroup>
  );
};

export default SearchNavigationList;
