import lodash from "lodash";
import { type ValidationResult } from "types/form";

class FieldValidator {
  private pushError(
    output: ValidationResult,
    field: string,
    error: string
  ): ValidationResult {
    output.validity = true;
    if (output.errors[field] === undefined) {
      output.errors[field] = [];
    }
    output.errors[field] = [
      ...output.errors[field],
      error
    ];
    return output;
  }

  isEmpty(
    output: ValidationResult,
    field: string,
    value: string,
    label?: string
  ): ValidationResult {
    if (lodash.isEmpty(value)) {
      this.pushError(
        output,
        field,
        `${label !== undefined ? label : field} is mandatory.`
      );
    }
    return output;
  }
}

const singleton = new FieldValidator();
export default singleton;
