import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { setBreadcrumbs } from "features/application";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";
import { type SearchedProfile } from "types/profile";
import TimeUtility from "utils/time";

interface Props {
  searchedProfiles: SearchedProfile[];
  parentComponent: "Profiles";
}

const SearchedProfilesTable: React.FC<Props> = ({
  searchedProfiles,
  parentComponent
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickTableRow = (searchedProfile: SearchedProfile): void => {
    const { profile } = searchedProfile;
    navigate(`/profiles/${profile.id}`, {
      state: { profileId: profile.id }
    });
    // TODO: parentComponent should be used
    dispatch(
      setBreadcrumbs([
        { label: "Profiles", href: "/profiles" },
        {
          label: `${profile.firstName} ${profile.lastName}`,
          href: `/profiles/${profile.id}`
        }
      ])
    );
  };

  return (
    <Grid container direction="column">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E5E7E9" }}>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Gender</TableCell>
              <TableCell align="center">Resident Country</TableCell>
              <TableCell align="center">Phone Number</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Last Seen At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedProfiles.map((searchedProfile, idx) => {
              const { profile } = searchedProfile;
              return (
                <TableRow
                  key={`table-row-${idx}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (searchedProfile.profile === undefined) {
                      return;
                    }
                    onClickTableRow(searchedProfile);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#E8DAEF"
                    }
                  }}
                >
                  {profile !== undefined ? (
                    <>
                      <TableCell align="center">{profile.id}</TableCell>
                      <TableCell align="center">{profile.firstName}</TableCell>
                      <TableCell align="center">{profile.lastName}</TableCell>
                      <TableCell align="center">{profile.gender}</TableCell>
                      <TableCell align="center">
                        {profile.residentCountry.name}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        align="center"
                        colSpan={5}
                        sx={{
                          backgroundColor: "#FBEEE6",
                          color: "#CA6F1E",
                          fontWeight: "bold"
                        }}
                      >
                        Information not available, due to a temporary profile
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    {searchedProfile.phoneNumber ?? "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {TimeUtility.formatUnixTimeToLocalDateTime(
                      searchedProfile.createdAt
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {TimeUtility.formatUnixTimeToLocalDateTime(
                      searchedProfile.lastSeen
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SearchedProfilesTable;
