/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";
import { useAppDispatch } from "hooks";
import { type SubscriptionInfo, type ProfileAdmin } from "types/profile";
import { getSubscriptionInfo } from "features/profles";
import HttpError, { type ApiError } from "types/generics/HttpError";
import TimeUtility from "utils/time";
import SubscriptionDetail from "../Subscription";

const FONT_COLOR = "#424949";

interface Props {
  profile: ProfileAdmin;
}

const SubscriptionInfoView: React.FC<Props> = ({ profile }) => {
  const dispatch = useAppDispatch();
  const [
    downloaded,
    setDownloaded
  ] = useState(false);
  const [
    downloading,
    setDownloading
  ] = useState(false);
  const [
    error,
    setError
  ] = useState<string | undefined>(undefined);
  const [
    subsriptionInfo,
    setSubscriptionInfo
  ] = useState<undefined | SubscriptionInfo>(undefined);

  const onClickDownload = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    try {
      setError(undefined);
      setDownloading(true);
      const response = await dispatch(
        getSubscriptionInfo({ profileId: profile.id })
      );
      if (response.payload instanceof HttpError) {
        setDownloaded(false);
        const statusCode = response.payload.status();
        if (statusCode === 404) {
          const responseBody = response.payload.body() as ApiError;
          const errorMsg = `${responseBody.description} (${responseBody.code})`;
          setError(errorMsg);
        } else {
          setError("Unknown Error");
        }
        return;
      }
      setDownloaded(true);
      setSubscriptionInfo(response.payload as SubscriptionInfo);
    } catch {
    } finally {
      setTimeout(() => {
        setDownloading(false);
      }, 500);
    }
  };

  const ContentView: React.FC = () => {
    if (subsriptionInfo !== undefined) {
      return (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            paddingX={1}
            paddingY={2}
            sx={{ color: FONT_COLOR }}
            container
            direction="column"
          >
            <SubscriptionDetail subscriptionInfo={subsriptionInfo} />
          </Grid>
        </Grid>
      );
    }

    if (downloading) {
      return (
        <Grid container direction="row" alignItems="center" columnGap={1}>
          <CircularProgress color="inherit" size={14} />
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyItems="flex-start"
        columnGap={1}
        width="fit-content"
        paddingY={1}
      >
        <Grid
          item
          container
          alignItems="center"
          width="fit-content"
          sx={{
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer"
            }
          }}
          onClick={(e) => {
            void onClickDownload(e);
          }}
        >
          <DownloadIcon />
          <Typography variant="caption">
            Download Subscription Information
          </Typography>
        </Grid>
        {error !== undefined && (
          <>
            <ErrorIcon color="error" />
            <Typography variant="caption" color="red">
              {error}
            </Typography>
          </>
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      bgcolor="white"
      border={1}
      borderRadius={2}
      borderColor="white"
      paddingX={2}
      paddingY={4}
      rowGap={2}
      sx={{ color: FONT_COLOR }}
    >
      <Grid item>
        <Typography variant="h6" fontSize={16}>
          Subscription Information
        </Typography>
      </Grid>
      <Grid item>
        <ContentView />
      </Grid>
    </Grid>
  );
};

export default SubscriptionInfoView;
