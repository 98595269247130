// add future user config keys here
export const userConfigKeys = ["in-review-profile-page-view"] as const;
export type UserConfigKey = (typeof userConfigKeys)[number];

export const initialUserConfigs: Record<UserConfigKey, string> = {
  "in-review-profile-page-view": "tableView"
};

export interface DropdownOption<KeyType> {
  label: string;
  value: KeyType;
}

export interface BreadcrumbMeta {
  label: string;
  href: string;
}

export interface IAppAlert {
  severity: "error" | "info" | "success" | "warning";
  message: string;
  timeout?: number;
}
