import React from "react";
import { type Location } from "react-router-dom";
import appTheme from "../../../theme";

interface ILayoutContextProps {
  drawerWidth: number;
  isMobileDevice: boolean;
  getNavIconColor: (location: Location, pathname: string) => string;
}

const LayoutContext = React.createContext<ILayoutContextProps>({
  drawerWidth: 220,
  isMobileDevice: false,
  getNavIconColor: (location, pathname) => {
    if (pathname === "/") {
      return pathname === location.pathname
        ? appTheme.palette.primary.main
        : appTheme.palette.common.white;
    }
    return location.pathname.includes(pathname)
      ? appTheme.palette.primary.main
      : appTheme.palette.common.white;
  }
});

export const LayoutProvider = LayoutContext.Provider;
export default LayoutContext;
