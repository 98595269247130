/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import { fetchEditRequestImage, fetchProfileImage } from "features/profles";
import { useAppDispatch } from "hooks";
import { useContext, useEffect, useState } from "react";
import HttpError from "types/generics/HttpError";
import {
  type ProfileImage as IProfileImage,
  type ProfileImage as Image
} from "types/profile";
import ImageViewer from "react-simple-image-viewer";
import LayoutContext from "layouts/Authenticated/context";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorHandler } from "helpers/error-handler";

interface Props {
  image: Image | string;
  referredBy?: "profile" | "edit-request";
  onDelete?: (id: string) => void;
}

const ProfileImage: React.FC<Props> = ({
  image,
  referredBy = "profile",
  onDelete
}) => {
  const dispatch = useAppDispatch();
  const [
    source,
    setSource
  ] = useState<string | undefined>(undefined);
  const [
    didFireDownload,
    setDidiFireDownload
  ] = useState(false);
  const [
    downloading,
    setDownloading
  ] = useState(false);
  const [
    openImageViewer,
    setOpenImageViewer
  ] = useState(false);
  const appTheme = useTheme();
  const { drawerWidth } = useContext(LayoutContext);

  const handleDownload = async (): Promise<unknown> => {
    if (referredBy === "edit-request") {
      const response = await dispatch(fetchEditRequestImage(image as string));
      return response.payload;
    }
    const response = await dispatch(
      fetchProfileImage({ image: image as IProfileImage })
    );
    return response.payload;
  };

  useEffect(() => {
    if (source === undefined) {
      setDidiFireDownload(true);
      setDownloading(true);
    }
  }, [source]);

  useEffect(() => {
    if (didFireDownload) {
      void handleDownload()
        .then((response) => {
          if (response instanceof HttpError) {
            ErrorHandler.processHttpError(dispatch, response.httpResponse());
            return;
          }
          setSource(response as string);
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  }, [didFireDownload]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      width={150}
      height={1}
      style={{
        borderStyle: "solid",
        borderColor: appTheme.palette.primary.main,
        borderWidth: 1,
        borderRadius: 5,
        cursor: "pointer"
      }}
    >
      {downloading ? (
        <Grid item>
          <CircularProgress size={14} />
        </Grid>
      ) : (
        <>
          <Grid item xs>
            <>
              <img
                src={source}
                style={{
                  objectFit: "contain",
                  width: 150,
                  height: 130,
                  padding: 5,
                  borderRadius: 5,
                  borderWidth: 5
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenImageViewer(true);
                }}
              />
              {openImageViewer && source !== undefined && (
                <ImageViewer
                  src={[source]}
                  backgroundStyle={{
                    backgroundColor: "rgba(213, 219, 219, 0.8)",
                    height: "90%",
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: `${drawerWidth}px`,
                    marginTop: 75
                  }}
                  closeComponent={
                    <IconButton
                      size="medium"
                      sx={{ backgroundColor: appTheme.palette.primary.main }}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenImageViewer(false);
                      }}
                    >
                      <CloseIcon htmlColor="white" />
                    </IconButton>
                  }
                />
              )}
            </>
          </Grid>
          {onDelete !== undefined && (
            <Grid item xs={1}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  if (typeof image === "string") {
                    onDelete(image);
                  } else {
                    onDelete(image.id);
                  }
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default ProfileImage;
