import axios, { AxiosError, type AxiosInstance } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { decamelizeKeys } from "humps";
import queryString from "query-string";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
type KeyValueMap = Record<string, any>;
type RequestBody = string | KeyValueMap | FormData | undefined;

type ContentType =
  | "application/x-www-form-urlencoded"
  | "application/json"
  | "multipart/form-data";

type RequestHeaders = Record<string, string>;
type QueryParams = Record<string, string>;

interface HTTPRequest {
  method: HttpMethod;
  apiVersion: "v1";
  path: string;
  authenticated: boolean;
  body?: RequestBody;
  contentType?: ContentType;
  headers?: RequestHeaders;
  queryParams?: QueryParams;
  reponseType?: "json" | "blob";
}

export interface HttpResponse {
  method: HttpMethod;
  baseURL: string;
  path: string;
  status: number;
  success: boolean;
  headers?: Record<string, any>;
  body?: any;
}

class HttpAdapter {
  private static singleton: HttpAdapter;
  private readonly pBaseURL: string;
  private readonly pDelegate: AxiosInstance;

  public get baseURL(): string {
    return this.pBaseURL;
  }

  public get delegate(): AxiosInstance {
    return this.pDelegate;
  }

  private constructor() {
    const apiBaseURL = process.env.REACT_APP_API_URL ?? "";
    this.pBaseURL = apiBaseURL !== null ? apiBaseURL : "";
    this.pDelegate = applyCaseMiddleware(
      axios.create({
        baseURL: this.pBaseURL
      })
    );
  }

  private static sanitizeRequest(request: HTTPRequest): void {
    request.headers = request.headers !== undefined ? request.headers : {};
    request.queryParams =
      request.queryParams !== undefined ? request.queryParams : {};
    request.reponseType =
      request.reponseType !== undefined ? request.reponseType : "json";

    if (request.body !== undefined) {
      if (request.body instanceof FormData) {
        request.contentType = "multipart/form-data";
      } else if (request.contentType === "application/x-www-form-urlencoded") {
        request.body = queryString.stringify(request.body as KeyValueMap);
      } else {
        request.contentType = "application/json";
      }
    }

    if (request.authenticated) {
      const accessToken = localStorage.getItem("access-token");
      if (accessToken != null) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  }

  private static getInstance(): HttpAdapter {
    if (HttpAdapter.singleton == null) {
      HttpAdapter.singleton = new HttpAdapter();
    }
    return HttpAdapter.singleton;
  }

  public static async doRequest(request: HTTPRequest): Promise<HttpResponse> {
    HttpAdapter.sanitizeRequest(request);
    const httpAdapter = this.getInstance();
    const requestPath = `${request.apiVersion}/${request.path}`;
    const { delegate, baseURL } = httpAdapter;
    try {
      const apiResponse = await delegate.request({
        url: requestPath,
        method: request.method,
        headers: request.headers,
        params: request.queryParams,
        data: request.body,
        responseType: request.reponseType
      });
      return {
        success: true,
        baseURL,
        path: requestPath,
        method: request.method,
        status: apiResponse.status,
        headers: apiResponse.headers,
        body: apiResponse.data
      };
    } catch (e) {
      const errorResponse: HttpResponse = {
        success: false,
        baseURL,
        path: requestPath,
        method: request.method,
        status: 600,
        headers: undefined,
        body: undefined
      };
      if (e instanceof AxiosError) {
        const axiosError = e as AxiosError;
        if (axiosError.response !== undefined) {
          errorResponse.status = axiosError.response.status;
          errorResponse.body = axiosError.response?.data;
          errorResponse.headers = axiosError.response?.headers;
        } else if (axiosError.request !== undefined) {
          errorResponse.status = axiosError.request?.status;
        }
      }
      return errorResponse;
    }
  }
}

export const decamelizeBody = (body: KeyValueMap): RequestBody => {
  return decamelizeKeys(body, { separator: "_" });
};

export const decamelizeQueryParams = (body: QueryParams): QueryParams => {
  return decamelizeKeys(body, { separator: "_" }) as QueryParams;
};

export default HttpAdapter;
