import { configureStore } from "@reduxjs/toolkit";
import ApplicationReducer from "features/application";
import ProfileReducer from "features/profles";

const store = configureStore({
  reducer: {
    application: ApplicationReducer,
    profiles: ProfileReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDisptch = typeof store.dispatch;

export default store;
