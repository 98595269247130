import { type ProfileStatus } from "types/profile";

export const ProfileStatusTagColor: Record<ProfileStatus, string> = {
  CREATED: "#5499C7",
  IN_REVIEW: "#D4AC0D",
  LIVE: "#229954",
  EXPIRED: "#CB4335",
  DISABLED: "#EC7063",
  PAUSED: "#f2b046",
  REVIEW_REJECTED: "#e60026"
};
