import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConfig from "config";
import AuthService from "services/api/auth";
import { type BreadcrumbMeta, type IAppAlert } from "types/application";
import { type LoginForm } from "types/form";
import { type UserAccount } from "types/user";

interface State {
  authenticated: boolean;
  preConfigured: boolean;
  authAccount: UserAccount | null;
  breadcrumbs: BreadcrumbMeta[];
  appAlert: IAppAlert | undefined;
}

const initialState: State = {
  authenticated: false,
  preConfigured: false,
  authAccount: null,
  breadcrumbs: [],
  appAlert: undefined
};

export const showAppAlert = createAsyncThunk(
  "application/showAppAlert",
  async (alert: IAppAlert, { dispatch }) => {
    const timeout = alert.timeout ?? AppConfig.DEFAULT_APP_ALERT_TIMEOUT;
    const { setAppAlert } = ApplicationSlice.actions;
    dispatch(setAppAlert(alert));
    setTimeout(() => {
      dispatch(setAppAlert(undefined));
    }, timeout);
  }
);

export const authLogin = createAsyncThunk(
  "application/auth-login",
  async (loginForm: LoginForm, { dispatch }) => {
    const response = await AuthService.login(loginForm);
    if (response.success) {
      const accessToken = response.body?.id;
      localStorage.setItem("access-token", accessToken);
      dispatch(setAuthenticated(true));
    }
  }
);

export const fetchAuthUserAccount = createAsyncThunk(
  "appliation/fetch-auth-user-account",
  async (arg, { dispatch }) => {
    const apiResponse = await AuthService.fetchAuthUserAccount();
    if (!apiResponse.success) {
      // return error handling
      return;
    }
    dispatch(setAuthAccount(apiResponse.body));
  }
);

export const logoutAuth = createAsyncThunk(
  "application/logout-auth",
  async (arg, { dispatch }) => {
    localStorage.removeItem("access-token");
    try {
      dispatch(setAuthAccount(null));
    } catch (e) {
      console.log("Error! logoutAuth ", e);
    } finally {
      dispatch(setAuthenticated(false));
    }
  }
);

export const runPreConfigurations = createAsyncThunk(
  "application/runPreConfigurations",
  async (arg, { dispatch }) => {
    try {
      const token = localStorage.getItem("access-token");
      if (token != null) {
        dispatch(setAuthenticated(true));
        dispatch(setBreadcrumbs([{ label: "Review Requests", href: "/" }]));
        await dispatch(fetchAuthUserAccount());
      }
    } catch (e) {
    } finally {
      setTimeout(() => {
        dispatch(setPreconfigured(true));
      }, 500);
    }
  }
);

const ApplicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setPreconfigured: (state, action) => {
      state.preConfigured = action.payload;
    },
    setAuthAccount: (state, action) => {
      state.authAccount = action.payload;
    },
    setBreadcrumbs: (
      state,
      action: { payload: BreadcrumbMeta[]; type: string }
    ) => {
      state.breadcrumbs = action.payload;
    },
    setAppAlert: (
      state,
      action: { type: string; payload: IAppAlert | undefined }
    ) => {
      state.appAlert = action.payload;
    }
  }
});

export const {
  setAuthenticated,
  setPreconfigured,
  setAuthAccount,
  setBreadcrumbs,
  setAppAlert
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
