// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Section from "components/molecules/Section";
import { useState } from "react";

interface Props {
  profileDescription: string;
}

const ProfileDescription: React.FC<Props> = ({ profileDescription }) => {
  const [
    seeMore,
    setSeeMore
  ] = useState(false);

  return (
    <Section name="profile description">
      {seeMore ? (
        <Typography>{profileDescription}</Typography>
      ) : (
        <Typography>{profileDescription.substring(0, 500)}</Typography>
      )}
      {profileDescription.length > 500 && (
        <Typography
          variant="body1"
          color={"#b7559b"}
          fontWeight={"bold"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setSeeMore(!seeMore);
          }}
        >
          {seeMore ? <>show less</> : <>see more</>}
        </Typography>
      )}
    </Section>
  );
};

export default ProfileDescription;
