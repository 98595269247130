import {
  Button,
  CircularProgress,
  Grid,
  ImageList,
  Typography
} from "@mui/material";
import { type EditRequest, type ProfileAdmin } from "types/profile";
import Section from "components/molecules/Section";
import moment from "moment";
import AppConfig from "config";
import ProfileImage from "../ProfileView/ProfileImage";
import { useState } from "react";
import { useAppDispatch } from "hooks";
import {
  acceptOrRejectEditRequest,
  fetchPendingEditRequests
} from "features/profles";
import HttpError from "types/generics/HttpError";
import { ErrorHandler } from "helpers/error-handler";
import ProfileDescription from "../ProfileView/profileDescription";

interface DataRowProps {
  label: string;
  value: string | number;
}
const DataRow: React.FC<DataRowProps> = ({ label, value }) => {
  return (
    <Grid
      item
      xs={12}
      md={5}
      lg={5}
      container
      direction="row"
      padding={1}
      bgcolor="#F2F3F4"
      columnGap={1}
      justifyContent="center"
    >
      <Typography variant="body2" fontWeight={500} textTransform="capitalize">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight={400}>
        {value}
      </Typography>
    </Grid>
  );
};

interface Props {
  profile: ProfileAdmin;
  editRequest: EditRequest;
  fetchProfile: () => Promise<void>;
}

const ProfileEditRequest: React.FC<Props> = ({ editRequest, fetchProfile }) => {
  const dispatch = useAppDispatch();
  const [
    processing,
    setProcessing
  ] = useState(false);

  const onActionClick = async (action: "accept" | "reject"): Promise<void> => {
    setProcessing(true);
    try {
      const response = await dispatch(
        acceptOrRejectEditRequest({ requestId: editRequest.id, action })
      );
      if (response instanceof HttpError) {
        ErrorHandler.processHttpError(dispatch, response.httpResponse());
        return;
      }
      void fetchProfile();
      void dispatch(fetchPendingEditRequests({ pageNum: 0 }));
    } catch (error) {
      ErrorHandler.process(dispatch, {
        severity: "error",
        message: "Unexpected Error"
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Grid container padding={2} rowGap={2}>
      <>
        <Grid item>
          <Typography variant="caption" color="#5D6D7E" fontSize={14}>
            <span style={{ fontWeight: 600, marginRight: 5 }}>Created At:</span>
            {moment(editRequest.createdAt).format(
              AppConfig.DEFAULT_DATETIME_FORMAT
            )}
          </Typography>
        </Grid>
        {editRequest.profileQuestion !== undefined && (
          <Section name="match question">
            <DataRow
              label="match question"
              value={editRequest.profileQuestion}
            />
          </Section>
        )}
        {editRequest.images !== undefined &&
          editRequest.images.length !== 0 && (
            <Section name="profile images">
              <ImageList cols={3} rowHeight={150}>
                {editRequest.images.map((fileName) => {
                  return (
                    <ProfileImage
                      key={fileName}
                      image={fileName}
                      referredBy="edit-request"
                    />
                  );
                })}
              </ImageList>
            </Section>
          )}
        {editRequest.description !== undefined && (
          <ProfileDescription
            profileDescription={editRequest.description}
          ></ProfileDescription>
        )}
      </>
      <Grid item xs={12} container columnGap={2} justifyContent="flex-end">
        <Grid
          item
          xs={6}
          md={6}
          lg={4}
          container
          direction="row"
          justifyContent="flex-end"
          columnGap={2}
        >
          {processing ? (
            <CircularProgress size={16} />
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  void onActionClick("accept");
                }}
              >
                Publish
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  void onActionClick("reject");
                }}
              >
                Reject
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileEditRequest;
