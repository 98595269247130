import { Grid, Typography } from "@mui/material";

interface Props {
  text: string | number;
  Icon: React.FC;
  fontColor?: string;
}
const IconizedText: React.FC<Props> = ({ Icon, text, fontColor }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      columnGap={1}
      sx={{ color: fontColor }}
      paddingY={0.25}
    >
      {<Icon />}
      <Typography variant="body1" fontSize={14}>
        {text}
      </Typography>
    </Grid>
  );
};

export default IconizedText;
