import { Grid, Typography, Checkbox } from "@mui/material";
import { type SubscriptionInfo } from "types/profile";
import TimeUtility from "utils/time";

const FONT_COLOR = "#424949";

interface RowProps {
  label: string;
  value: string | boolean;
  varaint: "body1" | "body2" | "caption";
}
const SubscriptionInfoRow: React.FC<RowProps> = ({
  label,
  value,
  varaint
}: RowProps) => {
  return (
    <Grid
      container
      direction="row"
      columnGap={1}
      sx={{ color: FONT_COLOR }}
      alignItems="center"
    >
      <Grid item xs={3}>
        <Typography
          variant={varaint}
          textTransform="capitalize"
          fontWeight={400}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        {typeof value === "boolean" ? (
          <Checkbox size="small" checked={value} disabled />
        ) : (
          <Typography variant={varaint}>{value}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface Props {
  subscriptionInfo: SubscriptionInfo;
}

const SubscriptionDetail: React.FC<Props> = ({ subscriptionInfo }) => {
  return (
    <Grid container direction="column">
      <Typography variant="h6" fontSize={16}>
        {subscriptionInfo.type}
      </Typography>
      <Grid item container paddingX={1} paddingY={1} rowGap={0.5}>
        <SubscriptionInfoRow
          varaint="body2"
          label="Order Id"
          value={subscriptionInfo.orderId}
        />
        <SubscriptionInfoRow varaint="body2" label="months" value="3" />
        {subscriptionInfo.currency !== undefined &&
          subscriptionInfo.amount !== undefined && (
            <SubscriptionInfoRow
              varaint="body2"
              label="Amount"
              value={`${subscriptionInfo.currency} ${subscriptionInfo.amount}`}
            />
          )}
        <SubscriptionInfoRow
          varaint="body2"
          label="start from"
          value={TimeUtility.formatUnixTimeToLocalDateTime(
            subscriptionInfo.currentStart
          )}
        />
        <SubscriptionInfoRow
          varaint="body2"
          label="Expired At"
          value={TimeUtility.formatUnixTimeToLocalDateTime(
            subscriptionInfo.currentExpiry
          )}
        />
        <SubscriptionInfoRow
          varaint="body2"
          label="auto renew"
          value={subscriptionInfo.autoRenew}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionDetail;
