/* eslint-disable @typescript-eslint/no-unused-vars */
import { Chip, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { type PublicProfile } from "types/profile";
import BoyPic from "assets/images/boy2.png";
import GirlPic from "assets/images/girl2.png";
import LaunchIcon from "@mui/icons-material/Launch";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import ReactCountryFlag from "react-country-flag";
import { useState } from "react";

interface Props {
  profile: PublicProfile;
}

const FONT_COLOR = "#404040";

const ProfileListitem: React.FC<Props> = ({ profile }) => {
  const appTheme = useTheme();
  const [
    isMouseEntered,
    setMouseEntered
  ] = useState(false);

  return (
    <Grid
      container
      direction="row"
      border={1}
      borderColor={isMouseEntered ? appTheme.palette.primary.main : "#555555"}
      padding={1}
      width={1}
      sx={{ color: FONT_COLOR }}
      borderRadius={3}
      onMouseEnter={(e) => {
        e.preventDefault();
        setMouseEntered(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setMouseEntered(false);
      }}
    >
      <Grid item alignSelf="center">
        <img
          src={profile.gender === "MALE" ? BoyPic : GirlPic}
          alt="boy"
          width={50}
        />
      </Grid>
      <Grid
        item
        xs={8}
        lg={10}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        paddingY={1}
        paddingX={1}
        columnGap={2}
      >
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize={isMouseEntered ? 18 : 16}
          color={isMouseEntered ? appTheme.palette.primary.main : FONT_COLOR}
          sx={{
            cursor: isMouseEntered ? "pointer" : "default",
            "&:hover": { textDecorationLine: "underline" }
          }}
        >
          {`${profile.firstName} ${profile.lastName}`}
        </Typography>
        <Grid
          item
          container
          xs
          direction="row"
          columnGap={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="body1" fontSize={14}>
            {profile.residentCountry.id}
          </Typography>
          <ReactCountryFlag
            countryCode={profile.residentCountry.id}
            title={profile.residentCountry.name}
            svg
            style={{
              width: "1.5m",
              height: "1.5em"
            }}
          />
          <Chip
            icon={<PersonIcon fontSize="small" />}
            label={profile.ethnicity.name}
            variant="outlined"
          />
          <Chip
            icon={<MenuBookIcon fontSize="small" />}
            label={profile.religion.name}
            variant="outlined"
          />
          <Chip
            icon={<SchoolIcon fontSize="small" />}
            label={profile.educationLevel.name}
            variant="outlined"
          />
          <Chip
            icon={<WorkIcon fontSize="small" />}
            label={profile.profession?.name ?? "N/A"}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs
        container
        direction="column"
        paddingX={1}
        alignItems="flex-end"
      >
        <IconButton size="medium">
          <LaunchIcon
            fontSize="small"
            htmlColor={appTheme.palette.primary.main}
          />
        </IconButton>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            variant="caption"
            color={isMouseEntered ? appTheme.palette.primary.main : FONT_COLOR}
            fontWeight={isMouseEntered ? "bold" : "normal"}
          >
            {profile.id}
          </Typography>
          <IconButton size="medium">
            <ContentCopyIcon
              fontSize="small"
              htmlColor={appTheme.palette.primary.main}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileListitem;
