import { Typography } from "@mui/material";

interface Props {
  text: string;
  fontColor: string;
  bgColor: string;
}

const Tag: React.FC<Props> = ({ text, bgColor, fontColor }) => {
  return (
    <Typography
      variant="caption"
      color={fontColor}
      bgcolor={bgColor}
      fontWeight={500}
      paddingX={2}
      paddingY={0.5}
      borderRadius={4}
      textTransform="capitalize"
    >
      {text}
    </Typography>
  );
};

export default Tag;
