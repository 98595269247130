/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
  useTheme,
  CircularProgress
} from "@mui/material";
import { useFormik } from "formik";
import { type LoginForm } from "types/form";
import AuthValidator, { Form } from "utils/validators/formValidator/auth";
import AuthService from "services/api/auth";
import HytchLogo from "assets/images/Logo-White.png";
import { isEmpty } from "lodash";
import { useAppDispatch } from "hooks";
import { authLogin } from "features/application";
import { useState } from "react";
import { ErrorHandler } from "helpers/error-handler";

const loginForm: LoginForm = {
  phoneNumber: "",
  password: ""
};

const LoginScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [
    processing,
    setProcessing
  ] = useState(false);
  const Formik = useFormik({
    initialValues: loginForm,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setProcessing(true);
        void dispatch(authLogin(values)).finally(() => {
          setTimeout(() => {
            setSubmitting(false);
            setProcessing(false);
          }, 3000);
        });
      } catch (error) {
        ErrorHandler.process(dispatch, {
          severity: "error",
          message: "Unexpected Error"
        });
      }
    },
    validate: (values) => {
      const validation = AuthValidator.validate<LoginForm>(Form.Login, values);
      return validation.errors;
    }
  });

  return (
    <form onSubmit={Formik.handleSubmit}>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.primary.main}
          paddingY={1}
          sx={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        >
          <img src={HytchLogo} alt="hytch-logo" height={75} />
        </Grid>
        <Grid item xs={12} marginTop={4}>
          <Typography variant="h4" textAlign="center">
            Welcome to Admin
          </Typography>
        </Grid>
        <Grid
          item
          width={1}
          container
          direction="row"
          justifyContent="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="username-field">Username</InputLabel>
              <Input
                id="username-field"
                value={Formik.values.phoneNumber}
                onChange={(e) => {
                  e.preventDefault();
                  void Formik.setFieldValue("phoneNumber", e.target.value);
                }}
              />
              {Formik.errors.phoneNumber !== undefined && (
                <FormHelperText sx={{ color: "#F74141" }}>
                  {Formik.errors.phoneNumber}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="password-field">Password</InputLabel>
              <Input
                id="password-field"
                value={Formik.values.password}
                onChange={(e) => {
                  e.preventDefault();
                  void Formik.setFieldValue("password", e.target.value);
                }}
                type="password"
              />
              {Formik.errors.password !== undefined && (
                <FormHelperText sx={{ color: "#F74141" }}>
                  {Formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Remember Me" />
            </FormGroup>
          </Grid>
          <Grid item xs={8}>
            {processing ? (
              <Grid container justifyContent="center">
                <CircularProgress size={30} />
              </Grid>
            ) : (
              <Button
                variant="contained"
                type="submit"
                disabled={Formik.isSubmitting}
                sx={{ paddingY: 2 }}
                fullWidth
              >
                Login
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginScreen;
