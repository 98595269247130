/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from "@mui/material";
import { type PublicProfile } from "types/profile";
import ProfileListitem from "../ProfileListItem";

interface State {
  profiles: PublicProfile[];
}
const ProfilesListView: React.FC<State> = ({ profiles }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="start"
      rowGap={2}
    >
      {profiles.map((profile) => (
        <ProfileListitem profile={profile} key={profile.id} />
      ))}
    </Grid>
  );
};

export default ProfilesListView;
