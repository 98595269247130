import { type HttpResponse } from "services/api/adapter";

class HttpError {
  private readonly response: HttpResponse;

  public constructor(response: HttpResponse) {
    this.response = response;
  }

  public status(): number {
    return this.response.status;
  }

  public body(): any {
    return this.response.body;
  }

  public httpResponse(): HttpResponse {
    return this.response;
  }
}

export interface ApiError {
  code: number;
  description: string;
}

export default HttpError;
