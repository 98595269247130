/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme
} from "@mui/material";
import theme from "theme";
import { useEffect, type ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  pathname: string | false;
  Icon: ReactNode;
  Badge?: ReactNode;
  label: string;
}

const NavigationItem: React.FC<IProps> = ({ pathname, Icon, label, Badge }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [
    activated,
    setActivated
  ] = useState(false);

  useEffect(() => {
    let _activated = false;
    if (pathname === false) {
      _activated = false;
    } else if (pathname !== "/") {
      _activated = location.pathname.includes(pathname);
    } else {
      _activated = location.pathname === pathname;
    }

    setActivated(_activated);

    return () => {
      setActivated(false);
    };
  }, [location]);

  const getFontColor = (): string => {
    return activated ? theme.palette.primary.main : theme.palette.common.white;
  };

  const getColor = (variaint: "font" | "background"): string => {
    switch (variaint) {
      case "font":
        return activated
          ? theme.palette.primary.main
          : theme.palette.common.white;
      case "background":
        return activated
          ? theme.palette.common.white
          : theme.palette.primary.main;
    }
  };

  return (
    <ListItemButton
      onClick={(e) => {
        e.preventDefault();
        if (typeof pathname === "string") {
          navigate(pathname);
        }
      }}
      sx={{ backgroundColor: getColor("background") }}
    >
      <ListItemIcon sx={{ marginLeft: 2 }}>{Icon}</ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          color: getColor("font")
        }}
      />
      {Badge !== undefined && Badge}
    </ListItemButton>
  );
};

export default NavigationItem;
