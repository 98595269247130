/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  useTheme
} from "@mui/material";
import type React from "react";
import { ProfileStatus, type ProfileAdmin, type UserMeta } from "types/profile";
import BoyPic from "assets/images/boy2.png";
import GirlPic from "assets/images/girl2.png";
import ProfileImage from "../ProfileImage";
import Tag from "components/molecules/Tag";
import ProfileAction from "components/organisms/ProfileAction";
import { ProfileStatusTagColor } from "theme/colors";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import IconizedText from "components/molecules/IconizedText";
import TimeUtility from "utils/time";
import ProfileDescription from "../profileDescription";

// const FONT_COLOR = "#424949";
const FONT_COLOR = "#5D6D7E";

interface Props {
  profile: ProfileAdmin;
  userMeta?: UserMeta;
  fetchProfile: () => Promise<void>;
  deleteProfileImage?: (image: string) => void;
}

const ProfileHeader: React.FC<Props> = ({
  profile,
  userMeta,
  fetchProfile,
  deleteProfileImage
}) => {
  const appTheme = useTheme();

  const getAvatar = (): string => {
    return profile.gender === "MALE" ? BoyPic : GirlPic;
  };

  const getFullName = (): string => {
    return `${profile.firstName} ${profile.lastName}`;
  };

  return (
    <>
      <Grid
        container
        direction="row"
        bgcolor="white"
        border={1}
        borderRadius={2}
        borderColor="white"
        paddingX={2}
        paddingY={4}
        columnGap={2}
        rowGap={3}
      >
        <Grid
          item
          width={80}
          height={80}
          border={2}
          borderRadius={15}
          borderColor={appTheme.palette.primary.main}
          container
          justifyContent="center"
          alignItems="center"
        >
          <img src={getAvatar()} alt="avatar" width={40} height={40} />
        </Grid>
        <Grid item xs container direction="row" justifyContent="flex-start">
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            columnGap={1}
          >
            <Typography variant="h6" color={FONT_COLOR}>
              {getFullName()}
            </Typography>
            <Tag
              text={profile.status}
              bgColor={ProfileStatusTagColor[profile.status]}
              fontColor="#F0F3F4"
            />
            {profile.trialMode && (
              <Tag text="Trail Mode" bgColor="#3e92cc" fontColor="white" />
            )}
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              color="#707B7C"
              fontWeight={500}
              fontSize={13}
            >
              created at:{" "}
              {TimeUtility.formatUnixTimeToLocalDateTime(profile.createdAt)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnGap={1}
          >
            <Grid item>
              <IconizedText
                Icon={AccountBoxIcon}
                text={profile.id}
                fontColor={FONT_COLOR}
              />
            </Grid>
            <Grid item>
              <IconizedText
                Icon={PhoneAndroidIcon}
                text={userMeta?.phoneNumber ?? "N/A"}
                fontColor={FONT_COLOR}
              />
            </Grid>
          </Grid>
        </Grid>
        {profile.profileImages !== undefined && (
          <Grid item xs={12} container>
            <ImageList cols={3} rowHeight={180}>
              {profile.profileImages.map((image) => (
                <ImageListItem key={image.id}>
                  <ProfileImage image={image} onDelete={deleteProfileImage} />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        )}
        {profile.description !== undefined && (
          <ProfileDescription profileDescription={profile.description} />
        )}
        {profile.status !== ProfileStatus.LIVE && (
          <ProfileAction.InReviewProfile
            profile={profile}
            fetchProfile={fetchProfile}
          />
        )}
      </Grid>
    </>
  );
};

export default ProfileHeader;
