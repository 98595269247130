/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import AppModal from "components/molecules/Modal";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchInReviewProfiles,
  publishProfile,
  rejectPendingInReview
} from "features/profles";
import HttpError from "types/generics/HttpError";
import { type ProfileAdmin } from "types/profile";
import { ErrorHandler } from "helpers/error-handler";

interface Props {
  profile: ProfileAdmin;
  fetchProfile: () => Promise<void>;
}

const InReviewProfile: React.FC<Props> = ({ profile, fetchProfile }) => {
  const dispatch = useAppDispatch();
  const appTheme = useTheme();
  const { inReviewProfiles } = useAppSelector((state) => state.profiles);
  const [
    showRejectModal,
    setShowRejectModal
  ] = useState(false);
  const [
    showPublishModal,
    setShowPublishModal
  ] = useState(false);
  const [
    shouldChangeSubscription,
    setShouldChangeSubscription
  ] = useState(false);
  const [
    subscriptionPackage,
    setSubscriptionPackage
  ] = useState(3);
  const [
    rejection,
    setRejection
  ] = useState("");
  const [
    loading,
    setLoading
  ] = useState(false);

  const executeReviewRejection = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await dispatch(
        rejectPendingInReview({ profileId: profile.id, rejection })
      );
      if (response.payload instanceof HttpError) {
        ErrorHandler.processHttpError(
          dispatch,
          response.payload.httpResponse()
        );
      } else {
        void fetchProfile();
        const { pageNum } = inReviewProfiles;
        void dispatch(fetchInReviewProfiles({ pageNum }));
      }
    } catch (e) {
      ErrorHandler.process(dispatch, {
        severity: "error",
        message: "Unexpected Error"
      });
    } finally {
      setShowRejectModal(false);
      setLoading(false);
    }
  };

  const executePublishProfile = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await dispatch(
        publishProfile({
          profileId: profile.id,
          months: subscriptionPackage.toString(),
          upgrade: shouldChangeSubscription
        })
      );
      if (response.payload instanceof HttpError) {
        ErrorHandler.processHttpError(
          dispatch,
          response.payload.httpResponse()
        );
      } else {
        if (fetchProfile !== undefined) {
          void fetchProfile();
        }
        void dispatch(
          fetchInReviewProfiles({ pageNum: inReviewProfiles.pageNum })
        );
      }
    } catch (error) {
      ErrorHandler.process(dispatch, {
        severity: "error",
        message: "Unexpected error occurred"
      });
    } finally {
      setShowPublishModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={12} container columnGap={1} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            setShowPublishModal(true);
          }}
        >
          Publish
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            setShowRejectModal(true);
          }}
        >
          Reject
        </Button>
      </Grid>
      <AppModal open={showRejectModal} heading="Confirm Review Rejection">
        <Grid container direction="column" width={350} rowGap={2}>
          <Typography variant="body2" fontSize={14}>
            please mention the reason for the rejection
          </Typography>
          <TextField
            label="Reason"
            multiline
            maxRows={4}
            focused
            value={rejection}
            onChange={(e) => {
              e.preventDefault();
              setRejection(e.target.value);
            }}
          />
          <Grid item xs container direction="row" alignItems="center">
            <Grid
              item
              xs
              container
              direction="row"
              columnGap={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              {loading ? (
                <CircularProgress size={18} />
              ) : (
                <>
                  <Button
                    variant="text"
                    onClick={(e) => {
                      e.preventDefault();
                      void executeReviewRejection();
                    }}
                    disabled={loading || rejection.length === 0}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="text"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRejectModal(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppModal>
      <AppModal open={showPublishModal} heading="Publish Profile">
        <Grid container direction="column" width={350} rowGap={1}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <Checkbox
              checked={shouldChangeSubscription}
              onChange={(e) => {
                e.preventDefault();
                setShouldChangeSubscription(!shouldChangeSubscription);
              }}
            />
            <Typography sx={{ cursor: "inherit" }}>
              Change Subscription Plan
            </Typography>
          </Grid>
          {shouldChangeSubscription && (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              columnGap={1}
            >
              <Typography>Package</Typography>
              <Select
                value={subscriptionPackage}
                label="Age"
                onChange={(e) => {
                  e.preventDefault();
                  setSubscriptionPackage(e.target.value as number);
                }}
                fullWidth
              >
                <MenuItem value={1}>1 Month</MenuItem>
                <MenuItem value={3}>3 Months</MenuItem>
                <MenuItem value={6}>6 Months</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            marginTop={1}
          >
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <>
                <Button
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault();
                    void executePublishProfile();
                  }}
                >
                  Publish
                </Button>
                <Button
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPublishModal(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </AppModal>
    </>
  );
};

const ProfileAction = {
  InReviewProfile
};

export default ProfileAction;
